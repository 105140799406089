import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import styles from './index.module.scss'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [path, setPath] = useState<string>('home') // home首页  ksty快速体验  yhsc用户手册
  useEffect(() => {
    if(pathname === '/ksty') {
      setPath('ksty')
    }else if(pathname === '/yhsc') {
      setPath('yhsc')
    }else {
      setPath('home')
    }
  }, [pathname])
  const items: MenuProps['items'] = [
    {
      label: (
        <a href="https://xqpcdns.007vin.com/download/Cargo.exe">
          Windows 下载
        </a>
      ),
      key: '1',
    },
    {
      label: (
        <a href="https://xqpcdns.007vin.com/download/Cargo.dmg">
          Mac 下载
        </a>
      ),
      key: '2',
    }
  ];

  const goto = (path: string, key: string) => {
    setPath(key)
    navigate(path + search)
  }
  return (
    <div className={styles['header']}>
      <div className={styles['header-lf']}>
        <img src="https://xqpstatics.007vin.com/public/img/logo_small.png" alt=""/>
        <span>小汽配</span>
      </div>
      <div className={styles['header-rt']}>
        <div className={path === 'home' ? `${styles['path-item']} ${styles['active']}` : styles['path-item']} onClick={() => goto('/', 'home')}>
          <span>首页</span>
          {
            path === 'home' ? <span className={styles['vertical']}></span> : null
          }
        </div>
        <div className={path === 'ksty' ? `${styles['path-item']} ${styles['active']}` : styles['path-item']} onClick={() => goto('/ksty', 'ksty')}>
          <span>快速体验</span>
          {
            path === 'ksty' ? <span className={styles['vertical']}></span> : null
          }
        </div>
        <div className={path === 'yhsc' ? `${styles['path-item']} ${styles['active']}` : styles['path-item']} onClick={() => goto('/yhsc', 'yhsc')}>
          <span>用户手册</span>
          {
            path === 'yhsc' ? <span className={styles['vertical']}></span> : null
          }
        </div>
        <Dropdown menu={{ items }} overlayStyle={{textAlign: 'center'}}>
          <div className={styles['down-center']}>下载中心</div>
        </Dropdown>
      </div>
    </div>
  )
}
export default Header